import * as React from 'react';

import { HorizontalYellowLineShape } from '../../Atoms/Icons/Icons';

import './ImplantationSingleCard.scss';
import Link from '../../Molecules/Link/Link';

const ImplantationSingleCard = ({ title, adresse, post_code, city, tel1, tel2, email, website, url }) => {
  return (
    <div className="implantation_single_card_component">

      <div className="text">
        <Link href={url}>
          <h3 className="title">
            <span>
              {title.length > 50 ? `${title.substring(0, 47)}...` : title}
            </span>
            <HorizontalYellowLineShape className="line" />
          </h3>
        </Link>

        <p className="adresse">
          {adresse}
          {post_code && (<> - {post_code}</>)}
          {city && (<> - {city}</>)}
        </p>
        <p className="phone">
          {tel1 && 'Tel. ' + tel1}

          {
            tel2 &&
            <span dangerouslySetInnerHTML={{ __html: '<br />Tel. ' + tel2 }} />
          }
        </p>
        <p className="email">{email || ''}</p>
        <p className="web">{website || ''}</p>
      </div>
    </div>
  );
};

ImplantationSingleCard.default = {
  title: 'Default Title',
  adresse: 'Default Adresse',
  details: 'Tel. 01 00 00 00 00'
};

export default ImplantationSingleCard;
